.BgSearchBoxPattern {
  background: linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255, 0));
}
.BgSearchBox {
  background-image: linear-gradient(
    90deg,
    rgb(255 255 255),
    rgb(255, 255, 255, 0)
  );
}
.SearchBoxRightImg {
  @apply w-96;
}
@screen lg {
  .SearchBoxRightImg {
    width: 100%;
  }
}

@screen xl {
  .BgSearchBoxPattern {
    background-image: url("/icons/searchbox-bg.svg");
    background-repeat: no-repeat;
  }
  .SearchBoxRightImg {
    width: calc(((100% - 2.2rem) / 100 * 41.666667) + 1.1rem);
  }
}
@screen ct {
  .SearchBoxRightImg {
    width: calc(((100% - 2.2rem) / 100 * 41.666667) + 1.3rem);
  }
}
@screen 2xl {
  .SearchBoxRightImg {
    width: calc(
      (theme("width.containerWidth") * 0.4) +
        (100% - theme("width.containerWidth")) / 2
    );
  }
}
