.MaskContentImage::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  /* background-image: url(/images/mask-new-1.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  mix-blend-mode: overlay;
  z-index: -1;
}
.InformationBanner :global(.swiper-wrapper) {
  padding-bottom: 35px;
}

@screen xl {
  .InformationBanner :global(.swiper-wrapper) {
    padding-bottom: 15px;
  }
}
